<template>
    <div class="main-layout" v-if="!isLoading" :class="{[contentType]: contentType}">
        <div class="left-container" v-if="!isColapseLeftMenu">
            <MainMenu/>
        </div>
        <div class="right-container" :style="`width: calc(100vw - ${width}px); max-width: calc(100vw - ${width}px)`">
            <div class="scrolable-main-content" >
                <router-view />
            </div>
            <colapse-button :isColapsedBlock="isColapseLeftMenu" @click="isColapseLeftMenu = !isColapseLeftMenu" :classStr="'left-menu'" />
        </div>
        <Loading />
    </div>
</template>

<script>
import MainMenu from '@/components/MainMenu.vue'
import Loading from '@/components/Loading.vue'
import { fetchData, urls} from '@/utils/urls.js'
import ColapseButton from '@/components/ColapseButton.vue'

export default {
    components: { MainMenu, Loading, ColapseButton },
    data() {
        return {
            isLoading: true,
            isColapseLeftMenu: false,
        }
    },

    computed: {
        width() {
            if(this.isColapseLeftMenu) return 0
            return 300
        },

        contentType() {
            const mainContentParams = this.$store.state.mainContentParams
            return mainContentParams?.contentType || 'no-content-type'
        }
    },

    async created() {
        let token = localStorage.getItem('userAuth')
        if(!token) {
            this.$router.push('/login')
            return 
        }

        this.isLoading = true
        this.$store.commit('setToken', JSON.parse(token))
        await this.setUser()
        await this.setMetaData()
        this.isLoading = false
    },

    methods: {
        async setUser() {
            let result = await fetchData( urls.me, 'GET')
            this.$store.commit('setUser', result)
        },

        async setMetaData() {
            let result = await fetchData( urls.metaData, 'GET')
            this.$store.commit('setMetaData', result)
        }

    }
}
</script>

export const Logotype = `
<svg viewBox="0 0 115 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.048 53.24C7.336 53.24 5.792 52.872 4.416 52.136C3.04 51.384 1.96 50.352 1.176 49.04C0.392 47.712 0 46.232 0 44.6C0 42.968 0.392 41.496 1.176 40.184C1.96 38.856 3.04 37.824 4.416 37.088C5.792 36.336 7.336 35.96 9.048 35.96C10.76 35.96 12.304 36.336 13.68 37.088C15.056 37.824 16.136 38.848 16.92 40.16C17.704 41.472 18.096 42.952 18.096 44.6C18.096 46.248 17.704 47.728 16.92 49.04C16.136 50.352 15.056 51.384 13.68 52.136C12.304 52.872 10.76 53.24 9.048 53.24ZM9.048 50.504C10.168 50.504 11.176 50.256 12.072 49.76C12.968 49.248 13.672 48.544 14.184 47.648C14.696 46.736 14.952 45.72 14.952 44.6C14.952 43.48 14.696 42.472 14.184 41.576C13.672 40.664 12.968 39.96 12.072 39.464C11.176 38.952 10.168 38.696 9.048 38.696C7.928 38.696 6.92 38.952 6.024 39.464C5.128 39.96 4.424 40.664 3.912 41.576C3.4 42.472 3.144 43.48 3.144 44.6C3.144 45.72 3.4 46.736 3.912 47.648C4.424 48.544 5.128 49.248 6.024 49.76C6.92 50.256 7.928 50.504 9.048 50.504Z"/>
<path d="M20.54 36.2H23.66V50.36H32.444V53H20.54V36.2Z"/>
<path d="M39.368 47.072V53H36.248V47.12L29.648 36.2H32.984L37.904 44.384L42.872 36.2H45.944L39.368 47.072Z"/>
<path d="M62.376 53L62.352 41.864L56.832 51.08H55.44L49.92 42.008V53H46.944V36.2H49.512L56.184 47.336L62.736 36.2H65.304L65.328 53H62.376Z"/>
<path d="M74.792 36.2C76.248 36.2 77.512 36.44 78.584 36.92C79.672 37.4 80.504 38.088 81.08 38.984C81.656 39.88 81.944 40.944 81.944 42.176C81.944 43.392 81.656 44.456 81.08 45.368C80.504 46.264 79.672 46.952 78.584 47.432C77.512 47.912 76.248 48.152 74.792 48.152H71V53H67.88V36.2H74.792ZM74.648 45.512C76.008 45.512 77.04 45.224 77.744 44.648C78.448 44.072 78.8 43.248 78.8 42.176C78.8 41.104 78.448 40.28 77.744 39.704C77.04 39.128 76.008 38.84 74.648 38.84H71V45.512H74.648Z"/>
<path d="M90.6 53.3095C88.28 53.3095 86.472 52.6615 85.176 51.3655C83.88 50.0535 83.232 48.1815 83.232 45.7495V36.2695H86.352V45.6295C86.352 48.9255 87.776 50.5735 90.624 50.5735C93.456 50.5735 94.872 48.9255 94.872 45.6295V36.2695H97.944V45.7495C97.944 48.1815 97.296 50.0535 96 51.3655C94.72 52.6615 92.92 53.3095 90.6 53.3095Z"/>
<path d="M105.152 53.24C103.856 53.24 102.6 53.056 101.384 52.688C100.184 52.32 99.232 51.832 98.528 51.224L99.608 48.8C100.296 49.344 101.136 49.792 102.128 50.144C103.136 50.48 104.144 50.648 105.152 50.648C106.4 50.648 107.328 50.448 107.936 50.048C108.56 49.648 108.872 49.12 108.872 48.464C108.872 47.984 108.696 47.592 108.344 47.288C108.008 46.968 107.576 46.72 107.048 46.544C106.52 46.368 105.8 46.168 104.888 45.944C103.608 45.64 102.568 45.336 101.768 45.032C100.984 44.728 100.304 44.256 99.728 43.616C99.168 42.96 98.888 42.08 98.888 40.976C98.888 40.048 99.136 39.208 99.632 38.456C100.144 37.688 100.904 37.08 101.912 36.632C102.936 36.184 104.184 35.96 105.656 35.96C106.68 35.96 107.688 36.088 108.68 36.344C109.672 36.6 110.528 36.968 111.248 37.448L110.264 39.872C109.528 39.44 108.76 39.112 107.96 38.888C107.16 38.664 106.384 38.552 105.632 38.552C104.4 38.552 103.48 38.76 102.872 39.176C102.28 39.592 101.984 40.144 101.984 40.832C101.984 41.312 102.152 41.704 102.488 42.008C102.84 42.312 103.28 42.552 103.808 42.728C104.336 42.904 105.056 43.104 105.968 43.328C107.216 43.616 108.24 43.92 109.04 44.24C109.84 44.544 110.52 45.016 111.08 45.656C111.656 46.296 111.944 47.16 111.944 48.248C111.944 49.176 111.688 50.016 111.176 50.768C110.68 51.52 109.92 52.12 108.896 52.568C107.872 53.016 106.624 53.24 105.152 53.24Z"/>
<path d="M1.2002 9.19996H8.5442C10.3362 9.19996 11.9282 9.55196 13.3202 10.256C14.7122 10.944 15.7922 11.928 16.5602 13.208C17.3282 14.472 17.7122 15.936 17.7122 17.6C17.7122 19.264 17.3282 20.736 16.5602 22.016C15.7922 23.28 14.7122 24.264 13.3202 24.968C11.9282 25.656 10.3362 26 8.5442 26H1.2002V9.19996ZM8.4002 23.36C9.6322 23.36 10.7122 23.128 11.6402 22.664C12.5842 22.184 13.3042 21.512 13.8002 20.648C14.3122 19.768 14.5682 18.752 14.5682 17.6C14.5682 16.448 14.3122 15.44 13.8002 14.576C13.3042 13.696 12.5842 13.024 11.6402 12.56C10.7122 12.08 9.6322 11.84 8.4002 11.84H4.3202V23.36H8.4002Z"/>
<path d="M21.0283 9.19996H24.1483V26H21.0283V9.19996Z"/>
<path d="M40.1644 17.408H43.1164V24.104C42.2524 24.792 41.2444 25.32 40.0924 25.688C38.9404 26.056 37.7564 26.24 36.5404 26.24C34.8284 26.24 33.2844 25.872 31.9084 25.136C30.5324 24.384 29.4524 23.352 28.6684 22.04C27.8844 20.728 27.4924 19.248 27.4924 17.6C27.4924 15.952 27.8844 14.472 28.6684 13.16C29.4524 11.848 30.5324 10.824 31.9084 10.088C33.3004 9.33596 34.8604 8.95996 36.5884 8.95996C37.9964 8.95996 39.2764 9.19196 40.4284 9.65596C41.5804 10.12 42.5484 10.8 43.3324 11.696L41.3644 13.616C40.0844 12.336 38.5404 11.696 36.7324 11.696C35.5484 11.696 34.4924 11.944 33.5644 12.44C32.6524 12.936 31.9324 13.632 31.4044 14.528C30.8924 15.424 30.6364 16.448 30.6364 17.6C30.6364 18.72 30.8924 19.728 31.4044 20.624C31.9324 21.52 32.6524 22.224 33.5644 22.736C34.4924 23.248 35.5404 23.504 36.7084 23.504C38.0204 23.504 39.1724 23.216 40.1644 22.64V17.408Z"/>
<path d="M47.2314 9.19996H50.3514V26H47.2314V9.19996Z"/>
<path d="M59.4442 11.84H53.8762V9.19996H68.1322V11.84H62.5642V26H59.4442V11.84Z"/>
<path d="M98.3242 9.2H101.444V23.36H110.228V26H98.3242V9.2Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.4438 35L80.9438 0L63.4438 35H64.4438L70.9438 26L73.9438 28.5L80.4438 23L82.4438 24.5L87.9438 19.5L98.4438 34.5L99.4438 35ZM83.1432 22.2242L87.6432 18.2242L87.2445 17.7758L82.956 21.5878L81.156 19.7879L80.9522 19.5841L80.7404 19.7796L74.4522 25.5841L72.656 23.7879L72.2317 24.2121L74.2317 26.2121L74.4355 26.4159L74.6473 26.2204L80.9355 20.4159L82.7317 22.2121L82.9317 22.4122L83.1432 22.2242Z"/>
</svg>
` // закрашивать с помощью fill

export const Cross = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.46458 15.5355L15.5356 8.46448" stroke-width="1.5"/>
    <path d="M8.46458 8.46445L15.5356 15.5355" stroke-width="1.5"/>
</svg>
` // закрашивать с помощью stroke

export const Cross2 = `
<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.06575 1.99481C9.35865 1.70191 9.35865 1.22704 9.06575 0.934147C8.77286 0.641254 8.29799 0.641254 8.00509 0.934147L4.99989 3.93935L1.99469 0.934148C1.7018 0.641255 1.22693 0.641255 0.934035 0.934148C0.641141 1.22704 0.641142 1.70191 0.934035 1.99481L3.93923 5.00001L0.934025 8.00522C0.641132 8.29811 0.641132 8.77298 0.934025 9.06588C1.22692 9.35877 1.70179 9.35877 1.99469 9.06588L4.99989 6.06067L8.0051 9.06588C8.298 9.35877 8.77287 9.35877 9.06576 9.06588C9.35865 8.77298 9.35865 8.29811 9.06576 8.00522L6.06055 5.00001L9.06575 1.99481Z"/>
</svg>

` // закрашивать с помощью fill

export const InfoCircle = `
<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 7.75C9.41421 7.75 9.75 8.08579 9.75 8.5V13.5C9.75 13.9142 9.41421 14.25 9 14.25C8.58579 14.25 8.25 13.9142 8.25 13.5V8.5C8.25 8.08579 8.58579 7.75 9 7.75Z"/>
<path d="M9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 9C0.25 4.16751 4.16751 0.25 9 0.25C13.8325 0.25 17.75 4.16751 17.75 9C17.75 13.8325 13.8325 17.75 9 17.75C4.16751 17.75 0.25 13.8325 0.25 9ZM9 1.75C4.99594 1.75 1.75 4.99594 1.75 9C1.75 13.0041 4.99594 16.25 9 16.25C13.0041 16.25 16.25 13.0041 16.25 9C16.25 4.99594 13.0041 1.75 9 1.75Z"/>
</svg>
` // закрашивать с помощью fill

export const CaretDown = `
<svg viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 0.96967C9.82322 1.26256 9.82322 1.73744 9.53033 2.03033L5.53033 6.03033C5.23744 6.32322 4.76256 6.32322 4.46967 6.03033L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L5 4.43934L8.46967 0.96967C8.76256 0.676777 9.23744 0.676777 9.53033 0.96967Z"/>
</svg>
` // закрашивать с помощью fill
export const CaretUp = `
<svg viewBox="0 0 10 7" transform="scale(-1 -1)" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 0.96967C9.82322 1.26256 9.82322 1.73744 9.53033 2.03033L5.53033 6.03033C5.23744 6.32322 4.76256 6.32322 4.46967 6.03033L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L5 4.43934L8.46967 0.96967C8.76256 0.676777 9.23744 0.676777 9.53033 0.96967Z"/>
</svg>
` // закрашивать с помощью fill

export const Dashboard = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.92906 4.47033C7.65784 4.32825 6.34193 4.32825 5.07071 4.47033C4.76182 4.50485 4.51823 4.74944 4.48348 5.0465C4.33168 6.34444 4.33168 7.65565 4.48348 8.95359C4.51823 9.25065 4.76182 9.49524 5.07071 9.52976C6.34193 9.67184 7.65784 9.67184 8.92906 9.52976C9.23795 9.49524 9.48154 9.25065 9.51629 8.95359C9.66809 7.65565 9.66809 6.34444 9.51629 5.0465C9.48154 4.74944 9.23795 4.50485 8.92906 4.47033ZM4.9041 2.97961C6.28605 2.82516 7.71372 2.82516 9.09567 2.97961C10.0865 3.09035 10.889 3.87034 11.0061 4.87225C11.1715 6.28596 11.1715 7.71413 11.0061 9.12784C10.889 10.1298 10.0865 10.9097 9.09567 11.0205C7.71372 11.1749 6.28605 11.1749 4.90411 11.0205C3.91327 10.9097 3.11082 10.1298 2.99364 9.12784C2.82829 7.71413 2.82829 6.28596 2.99364 4.87225C3.11082 3.87034 3.91326 3.09035 4.9041 2.97961Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.92906 14.4703C7.65784 14.3282 6.34193 14.3282 5.07071 14.4703C4.76182 14.5049 4.51823 14.7494 4.48348 15.0465C4.33168 16.3444 4.33168 17.6557 4.48348 18.9536C4.51823 19.2506 4.76182 19.4952 5.07071 19.5298C6.34193 19.6718 7.65784 19.6718 8.92906 19.5298C9.23795 19.4952 9.48154 19.2506 9.51629 18.9536C9.66809 17.6556 9.66809 16.3444 9.51629 15.0465C9.48154 14.7494 9.23795 14.5049 8.92906 14.4703ZM4.9041 12.9796C6.28605 12.8252 7.71372 12.8252 9.09567 12.9796C10.0865 13.0904 10.889 13.8703 11.0061 14.8722C11.1715 16.286 11.1715 17.7141 11.0061 19.1278C10.889 20.1298 10.0865 20.9097 9.09567 21.0205C7.71372 21.1749 6.28605 21.1749 4.90411 21.0205C3.91327 20.9097 3.11082 20.1298 2.99364 19.1278C2.82829 17.7141 2.82829 16.286 2.99364 14.8722C3.11082 13.8703 3.91326 13.0904 4.9041 12.9796Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.9291 4.47033C17.6578 4.32825 16.3419 4.32825 15.0707 4.47033C14.7618 4.50485 14.5182 4.74944 14.4835 5.0465C14.3317 6.34444 14.3317 7.65565 14.4835 8.95359C14.5182 9.25065 14.7618 9.49524 15.0707 9.52976C16.3419 9.67184 17.6578 9.67184 18.9291 9.52976C19.2379 9.49524 19.4815 9.25065 19.5163 8.95359C19.6681 7.65565 19.6681 6.34444 19.5163 5.0465C19.4815 4.74944 19.2379 4.50485 18.9291 4.47033ZM14.9041 2.97961C16.2861 2.82516 17.7137 2.82516 19.0957 2.97961C20.0865 3.09035 20.889 3.87034 21.0061 4.87225C21.1715 6.28596 21.1715 7.71413 21.0061 9.12784C20.889 10.1298 20.0865 10.9097 19.0957 11.0205C17.7137 11.1749 16.2861 11.1749 14.9041 11.0205C13.9133 10.9097 13.1108 10.1298 12.9936 9.12784C12.8283 7.71413 12.8283 6.28596 12.9936 4.87225C13.1108 3.87034 13.9133 3.09035 14.9041 2.97961Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.9291 14.4703C17.6578 14.3282 16.3419 14.3282 15.0707 14.4703C14.7618 14.5049 14.5182 14.7494 14.4835 15.0465C14.3317 16.3444 14.3317 17.6557 14.4835 18.9536C14.5182 19.2506 14.7618 19.4952 15.0707 19.5298C16.3419 19.6718 17.6578 19.6718 18.9291 19.5298C19.2379 19.4952 19.4815 19.2506 19.5163 18.9536C19.6681 17.6556 19.6681 16.3444 19.5163 15.0465C19.4815 14.7494 19.2379 14.5049 18.9291 14.4703ZM14.9041 12.9796C16.2861 12.8252 17.7137 12.8252 19.0957 12.9796C20.0865 13.0904 20.889 13.8703 21.0061 14.8722C21.1715 16.286 21.1715 17.7141 21.0061 19.1278C20.889 20.1298 20.0865 20.9097 19.0957 21.0205C17.7137 21.1749 16.2861 21.1749 14.9041 21.0205C13.9133 20.9097 13.1108 20.1298 12.9936 19.1278C12.8283 17.7141 12.8283 16.286 12.9936 14.8722C13.1108 13.8703 13.9133 13.0904 14.9041 12.9796Z"/>
</svg>
` // закрашивать с помощью fill

export const LinkBuilderPartners = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.6799 9.98322C18.7008 10.114 18.7204 10.245 18.7388 10.3761C18.7563 10.501 18.8492 10.6014 18.9704 10.6363C19.3282 10.7393 19.6625 10.8979 19.9633 11.1021C20.1138 11.2043 20.3347 11.0911 20.3155 10.9102C20.2744 10.5215 20.2229 10.1335 20.1612 9.74679L20.0971 9.34535C19.8912 8.05533 18.7785 7.10612 17.4721 7.10612L9.15768 7.10612C8.95216 6.04846 8.0209 5.25 6.90314 5.25H4.61158C3.25529 5.25 2.11289 6.26343 1.95118 7.61004L1.6787 9.87915C1.38904 12.2913 1.46379 14.7333 1.90046 17.1232C2.11598 18.3027 3.10248 19.1869 4.29851 19.2725L5.81252 19.3808C7.99325 19.5368 10.1792 19.5943 12.3639 19.5533C12.5027 19.5507 12.6203 19.4524 12.6567 19.3183C12.7479 18.9821 12.8772 18.6615 13.0395 18.3615C13.1166 18.219 13.014 18.0378 12.8521 18.042C10.5407 18.1022 8.22711 18.0497 5.91956 17.8846L4.40555 17.7763C3.89208 17.7396 3.46856 17.36 3.37603 16.8536C2.9664 14.6116 2.89627 12.3208 3.168 10.058L3.44048 7.78888C3.51167 7.19611 4.01455 6.75 4.61158 6.75H6.90314C7.34319 6.75 7.69991 7.10672 7.69991 7.54677C7.69991 8.13183 8.1742 8.60612 8.75926 8.60612H17.4721C18.0413 8.60612 18.5261 9.0197 18.6159 9.58178L18.6799 9.98322Z"/>
<path d="M17.9999 12C16.8953 12 15.9999 12.8954 15.9999 14C15.9999 15.1046 16.8953 16 17.9999 16C19.1045 16 19.9999 15.1046 19.9999 14C19.9999 12.8954 19.1045 12 17.9999 12Z"/>
<path d="M13.9999 20.5C13.9999 18.8431 15.3431 17.5 16.9999 17.5H18.9999C20.6568 17.5 21.9999 18.8431 21.9999 20.5C21.9999 21.0523 21.5522 21.5 20.9999 21.5H14.9999C14.4476 21.5 13.9999 21.0523 13.9999 20.5Z"/>
</svg>
` // закрашивать с помощью fill

export const Users = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 7.5C7.75 5.15279 9.65279 3.25 12 3.25C14.3472 3.25 16.25 5.15279 16.25 7.5C16.25 9.84721 14.3472 11.75 12 11.75C9.65279 11.75 7.75 9.84721 7.75 7.5ZM12 4.75C10.4812 4.75 9.25 5.98122 9.25 7.5C9.25 9.01878 10.4812 10.25 12 10.25C13.5188 10.25 14.75 9.01878 14.75 7.5C14.75 5.98122 13.5188 4.75 12 4.75Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 14.75C6.75736 14.75 5.75 15.7574 5.75 17V18.1883C5.75 18.2064 5.76311 18.2218 5.78097 18.2247C9.89972 18.8972 14.1003 18.8972 18.219 18.2247C18.2369 18.2218 18.25 18.2064 18.25 18.1883V17C18.25 15.7574 17.2426 14.75 16 14.75H15.6591C15.6328 14.75 15.6066 14.7542 15.5815 14.7623L14.716 15.045C12.9512 15.6212 11.0488 15.6212 9.28398 15.045L8.41847 14.7623C8.39342 14.7542 8.36722 14.75 8.34087 14.75H8ZM4.25 17C4.25 14.9289 5.92893 13.25 8 13.25H8.34087C8.52536 13.25 8.70869 13.2792 8.88407 13.3364L9.74959 13.6191C11.2119 14.0965 12.7881 14.0965 14.2504 13.6191L15.1159 13.3364C15.2913 13.2792 15.4746 13.25 15.6591 13.25H16C18.0711 13.25 19.75 14.9289 19.75 17V18.1883C19.75 18.9415 19.2041 19.5837 18.4607 19.7051C14.1819 20.4037 9.8181 20.4037 5.53927 19.7051C4.79588 19.5837 4.25 18.9415 4.25 18.1883V17Z"/>
</svg>
` // закрашивать с помощью fill

export const Links = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.75488 12C4.75488 7.99594 8.00082 4.75 12.0049 4.75C16.0089 4.75 19.2549 7.99594 19.2549 12C19.2549 12.0337 19.2571 12.0668 19.2614 12.0993C19.2582 12.1307 19.2569 12.1626 19.2577 12.1949C19.2638 12.4495 19.2312 12.8444 19.1476 13.2219C19.0565 13.6331 18.9379 13.8694 18.8685 13.9423C18.5666 14.2591 18.1512 14.4429 17.7137 14.4535C17.2762 14.464 16.8525 14.3003 16.5357 13.9984C16.3285 13.801 16.2692 13.5113 16.253 12.8381L16.1375 8.04002C16.1275 7.62592 15.7838 7.29831 15.3697 7.30828C14.9556 7.31825 14.628 7.66201 14.6379 8.07611L14.6546 8.76875C13.9335 8.17672 13.0107 7.82135 12.0049 7.82135C9.6971 7.82135 7.82629 9.69216 7.82629 11.9999C7.82629 14.3077 9.6971 16.1785 12.0049 16.1785C13.2793 16.1785 14.4204 15.608 15.1869 14.7084C15.2728 14.8392 15.3762 14.9655 15.5008 15.0843C16.1056 15.6606 16.9146 15.9731 17.7498 15.953C18.585 15.9329 19.378 15.5819 19.9543 14.9771C20.324 14.5892 20.5111 14.0022 20.6121 13.5463C20.7206 13.0566 20.7664 12.5406 20.7573 12.1588C20.7566 12.1313 20.7545 12.1042 20.7509 12.0775C20.7535 12.052 20.7549 12.0262 20.7549 12C20.7549 7.16751 16.8374 3.25 12.0049 3.25C7.17239 3.25 3.25488 7.16751 3.25488 12C3.25488 16.8325 7.17239 20.75 12.0049 20.75C14.0479 20.75 15.9293 20.0489 17.4185 18.8747C17.7438 18.6182 17.7996 18.1466 17.5431 17.8213C17.2866 17.4961 16.815 17.4403 16.4898 17.6968C15.2557 18.6698 13.6991 19.25 12.0049 19.25C8.00082 19.25 4.75488 16.0041 4.75488 12ZM12.0049 9.32135C10.5255 9.32135 9.32629 10.5206 9.32629 11.9999C9.32629 13.4793 10.5255 14.6785 12.0049 14.6785C13.4842 14.6785 14.6834 13.4793 14.6834 11.9999C14.6834 10.5206 13.4842 9.32135 12.0049 9.32135Z"/>
</svg>
` // закрашивать с помощью fill

export const Customers = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.25009 6.63104V5.46126C7.25009 4.59501 7.88384 3.85912 8.7405 3.73062L9.96041 3.54763C11.3126 3.3448 12.6875 3.3448 14.0398 3.54763L15.2597 3.73062C16.1163 3.85912 16.7501 4.59501 16.7501 5.46126V6.63104L18.4641 6.76939C19.7765 6.87533 20.847 7.86347 21.0575 9.16319C21.5227 12.0357 21.5227 14.9644 21.0575 17.8369C20.847 19.1367 19.7765 20.1248 18.4641 20.2307L16.5922 20.3818C13.5358 20.6285 10.4644 20.6285 7.40796 20.3818L5.53605 20.2307C4.22366 20.1248 3.15312 19.1367 2.94264 17.8369C2.47745 14.9644 2.47745 12.0357 2.94264 9.16319C3.15312 7.86347 4.22366 6.87533 5.53605 6.76939L7.25009 6.63104ZM10.1829 5.03104C11.3876 4.85033 12.6126 4.85033 13.8173 5.03104L15.0372 5.21402C15.1596 5.23238 15.2501 5.33751 15.2501 5.46126V6.52586C13.0852 6.40239 10.915 6.40239 8.75009 6.52586V5.46126C8.75009 5.33751 8.84063 5.23238 8.96301 5.21402L10.1829 5.03104ZM7.52865 8.11343C10.5048 7.8732 13.4954 7.8732 16.4715 8.11343L18.3434 8.26453C18.9676 8.31491 19.4767 8.78485 19.5768 9.40298C19.6392 9.78791 19.6926 10.1739 19.7373 10.5607C14.8597 12.9625 9.14047 12.9625 4.26287 10.5607C4.30752 10.1739 4.36101 9.78791 4.42335 9.40298C4.52345 8.78485 5.03258 8.31491 5.65673 8.26453L7.52865 8.11343ZM4.12898 12.1567C9.12838 14.4304 14.8718 14.4304 19.8712 12.1567C19.9665 13.9729 19.8684 15.7967 19.5768 17.5971C19.4767 18.2153 18.9676 18.6852 18.3434 18.7356L16.4715 18.8867C13.4954 19.1269 10.5048 19.1269 7.52865 18.8867L5.65673 18.7356C5.03258 18.6852 4.52345 18.2153 4.42335 17.5971C4.13178 15.7967 4.03366 13.9729 4.12898 12.1567Z"/>
</svg>
` // закрашивать с помощью fill

export const RolesAndPermissions = `
<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.50006 14C6.50006 13.1716 7.17163 12.5 8.00006 12.5C8.82849 12.5 9.50006 13.1716 9.50006 14C9.50006 14.8284 8.82849 15.5 8.00006 15.5C7.17163 15.5 6.50006 14.8284 6.50006 14Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.62171 8.59713L3.30627 5.75816C3.26583 5.39418 3.26583 5.02684 3.30627 4.66286L3.32904 4.45796C3.57053 2.28457 5.27913 0.564916 7.45091 0.309412C7.81574 0.266492 8.18434 0.266492 8.54916 0.309412C10.7209 0.564916 12.4295 2.28458 12.671 4.45797L12.6938 4.66286C12.7342 5.02684 12.7342 5.39418 12.6938 5.75815L12.3784 8.59712L13.065 8.65194C14.1477 8.73837 15.0318 9.55228 15.2073 10.6242C15.5734 12.8598 15.5734 15.1401 15.2073 17.3758C15.0318 18.4477 14.1477 19.2616 13.065 19.348L11.5688 19.4675C9.19345 19.6571 6.80673 19.6571 4.43134 19.4675L2.93521 19.348C1.85249 19.2616 0.96838 18.4477 0.792864 17.3758C0.426793 15.1401 0.426793 12.8598 0.792864 10.6242C0.96838 9.55228 1.85248 8.73837 2.93521 8.65194L3.62171 8.59713ZM7.62617 1.79914C7.87456 1.76992 8.12551 1.76992 8.3739 1.79914C9.85253 1.97309 11.0158 3.1439 11.1802 4.62361L11.203 4.82851C11.2312 5.08239 11.2312 5.33862 11.203 5.59251L10.8818 8.48296C8.96262 8.35938 7.03746 8.35938 5.11826 8.48296L4.7971 5.59251C4.76889 5.33862 4.76889 5.08239 4.7971 4.82851L4.81986 4.62361C4.98428 3.1439 6.14755 1.97309 7.62617 1.79914ZM11.4495 10.0277C9.15353 9.84445 6.84664 9.84445 4.55071 10.0277L3.05458 10.1472C2.65965 10.1787 2.33717 10.4756 2.27315 10.8666C1.93336 12.9417 1.93336 15.0583 2.27315 17.1334C2.33717 17.5244 2.65965 17.8213 3.05458 17.8528L4.55071 17.9722C6.84664 18.1555 9.15354 18.1555 11.4495 17.9722L12.9456 17.8528C13.3405 17.8213 13.663 17.5244 13.727 17.1334C14.0668 15.0583 14.0668 12.9417 13.727 10.8666C13.663 10.4756 13.3405 10.1787 12.9456 10.1472L11.4495 10.0277Z"/>
</svg>
` // закрашивать с помощью fill

export const Contacts = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.6023 18.6359C11.7302 18.7127 11.8671 18.7467 12 18.745C12.1329 18.7467 12.2698 18.7127 12.3977 18.6359L13.533 17.9548C15.7595 16.6189 18.4825 16.4007 20.8933 17.365C21.7826 17.7207 22.75 17.0658 22.75 16.1079V4.44906C22.75 3.87111 22.3981 3.35139 21.8615 3.13674C18.7564 1.89471 15.2492 2.17573 12.3815 3.89635L12 4.12527L11.6185 3.89635C8.75077 2.17573 5.24357 1.89471 2.13848 3.13674C1.60187 3.35139 1.25 3.87111 1.25 4.44906V16.1079C1.25 17.0658 2.21739 17.7207 3.10672 17.365C5.51752 16.4007 8.24052 16.6189 10.467 17.9548L11.6023 18.6359ZM2.75 4.50788V15.8947C5.54579 14.8466 8.67179 15.1283 11.2388 16.6685L11.25 16.6753V5.42455L10.8467 5.18259C8.39774 3.7132 5.40634 3.46486 2.75 4.50788ZM12.7612 16.6685L12.75 16.6753V5.42455L13.1533 5.18259C15.6023 3.7132 18.5937 3.46486 21.25 4.50788V15.8947C18.4542 14.8466 15.3282 15.1283 12.7612 16.6685Z"/>
<path d="M9.27516 19.0421C7.2513 17.8616 4.7487 17.8616 2.72484 19.0421L2.6221 19.1021C2.26431 19.3108 2.14346 19.77 2.35217 20.1278C2.56088 20.4856 3.02011 20.6065 3.3779 20.3977L3.48065 20.3378C5.03746 19.4297 6.96254 19.4297 8.51935 20.3378L9.60661 20.972C11.0856 21.8348 12.9144 21.8348 14.3934 20.972L15.4806 20.3378C17.0375 19.4297 18.9625 19.4297 20.5194 20.3378L20.6221 20.3977C20.9799 20.6065 21.4391 20.4856 21.6478 20.1278C21.8565 19.77 21.7357 19.3108 21.3779 19.1021L21.2752 19.0421C19.2513 17.8616 16.7487 17.8616 14.7248 19.0421L13.6376 19.6764C12.6257 20.2667 11.3743 20.2667 10.3624 19.6764L9.27516 19.0421Z"/>
</svg>
` // закрашивать с помощью fill

export const Settings = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.07922L9.78722 5.36872C9.55169 5.6124 9.22731 5.75002 8.8884 5.75002H5.75002V8.8884C5.75002 9.22731 5.6124 9.55169 5.36872 9.78722L3.07922 12L5.36872 14.2128C5.61241 14.4483 5.75002 14.7727 5.75002 15.1116V18.25H8.8884C9.22731 18.25 9.55169 18.3876 9.78721 18.6313L12 20.9208L14.2128 18.6313C14.4483 18.3876 14.7727 18.25 15.1116 18.25H18.25V15.1116C18.25 14.7727 18.3876 14.4483 18.6313 14.2128L20.9208 12L18.6313 9.78722C18.3876 9.5517 18.25 9.22732 18.25 8.88841V5.75002H15.1116C14.7727 5.75002 14.4483 5.61241 14.2128 5.36872L12 3.07922ZM11.1012 1.85078C11.5926 1.34238 12.4075 1.34238 12.8988 1.85078L15.2177 4.25002H18.5C19.1904 4.25002 19.75 4.80966 19.75 5.50002V8.78235L22.1493 11.1012C22.6576 11.5926 22.6577 12.4075 22.1493 12.8988L19.75 15.2177V18.5C19.75 19.1904 19.1904 19.75 18.5 19.75H15.2177L12.8988 22.1492C12.4075 22.6576 11.5926 22.6576 11.1012 22.1492L8.78235 19.75H5.50002C4.80966 19.75 4.25002 19.1904 4.25002 18.5V15.2177L1.85078 12.8988C1.34238 12.4075 1.34238 11.5926 1.85078 11.1012L4.25002 8.78235V5.50002C4.25002 4.80966 4.80966 4.25002 5.50002 4.25002H8.78235L11.1012 1.85078Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.25002 12C7.25002 9.37666 9.37666 7.25002 12 7.25002C14.6234 7.25002 16.75 9.37666 16.75 12C16.75 14.6234 14.6234 16.75 12 16.75C9.37666 16.75 7.25002 14.6234 7.25002 12ZM12 8.75002C10.2051 8.75002 8.75002 10.2051 8.75002 12C8.75002 13.7949 10.2051 15.25 12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75002 12 8.75002Z"/>
</svg>
` // закрашивать с помощью fill

export const NavBack = `
<svg viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5682 0.806757C13.2273 1.46577 13.2273 2.53423 12.5682 3.19324L4.76149 11L12.5682 18.8068C13.2273 19.4658 13.2273 20.5342 12.5682 21.1932C11.9092 21.8523 10.8408 21.8523 10.1818 21.1932L1.18176 12.1932C0.522748 11.5342 0.522748 10.4658 1.18176 9.80676L10.1818 0.806757C10.8408 0.147748 11.9092 0.147748 12.5682 0.806757Z"/>
</svg>
` // закрашивать с помощью fill

export const NavForward = `
<svg viewBox="0 0 14 22" transform="scale(-1 1)" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5682 0.806757C13.2273 1.46577 13.2273 2.53423 12.5682 3.19324L4.76149 11L12.5682 18.8068C13.2273 19.4658 13.2273 20.5342 12.5682 21.1932C11.9092 21.8523 10.8408 21.8523 10.1818 21.1932L1.18176 12.1932C0.522748 11.5342 0.522748 10.4658 1.18176 9.80676L10.1818 0.806757C10.8408 0.147748 11.9092 0.147748 12.5682 0.806757Z"/>
</svg>
` // закрашивать с помощью fill

export const Filter = `
<svg viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.9857 2.4238C10.0076 1.97918 5.99224 1.97918 2.01407 2.4238C1.7449 2.45388 1.61528 2.76953 1.78562 2.9801L5.30264 7.32765C6.56254 8.88507 7.2499 10.8276 7.2499 12.8308V15.7201L8.7499 16.8201V12.8308C8.7499 10.8276 9.43726 8.88507 10.6972 7.32765L14.2142 2.9801C14.3845 2.76953 14.2549 2.45388 13.9857 2.4238ZM1.84746 0.933078C5.93636 0.476083 10.0634 0.476083 14.1523 0.933078C15.5993 1.09479 16.2961 2.79158 15.3804 3.92351L11.8633 8.27106C10.8194 9.56149 10.2499 11.171 10.2499 12.8308V18.3001C10.2499 18.5827 10.091 18.8414 9.83891 18.9691C9.5868 19.0969 9.28429 19.0721 9.05637 18.9049L6.05637 16.7049C5.86372 16.5636 5.7499 16.339 5.7499 16.1001V12.8308C5.7499 11.171 5.18037 9.56149 4.13646 8.27106L0.619437 3.92351C-0.296253 2.79158 0.400531 1.09479 1.84746 0.933078Z"/>
</svg>
` // закрашивать с помощью fill

export const Search = `
<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.3849 12.4458C8.73463 14.5685 4.8552 14.4014 2.39842 11.9446C-0.237623 9.30856 -0.237623 5.0347 2.39842 2.39866C5.03445 -0.237379 9.30832 -0.237379 11.9444 2.39866C14.4011 4.85544 14.5682 8.73487 12.4456 11.3851L17.6012 16.5408C17.8941 16.8337 17.8941 17.3085 17.6012 17.6014C17.3083 17.8943 16.8334 17.8943 16.5405 17.6014L11.3849 12.4458ZM3.45908 10.8839C1.40882 8.83369 1.40882 5.50957 3.45908 3.45932C5.50933 1.40907 8.83344 1.40907 10.8837 3.45932C12.9324 5.50807 12.9339 8.82881 10.8882 10.8794C10.8867 10.8809 10.8852 10.8824 10.8837 10.8839C10.8822 10.8854 10.8807 10.8869 10.8792 10.8884C8.82857 12.9342 5.50783 12.9327 3.45908 10.8839Z"/>
</svg>
` // закрашивать с помощью fill

export const Plus = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 17V7" stroke-width="1.5" stroke-linecap="round"/>
<path d="M7 12L17 12" stroke-width="1.5" stroke-linecap="round"/>
</svg>
` // закрашивать с помощью stroke

export const Checkbox = `
<svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="13" height="13" rx="2.5"/>
</svg>
` // закрашивать с помощью stroke

export const CheckboxChecked = `
<svg viewBox="0 0 14 14" fill="none"  xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="13" height="13" rx="2.5"/>
<path d="M4 7L6 9L10 5" stroke="#0B9F0B" stroke-linecap="round" stroke-linejoin="round"></path>
</svg>
` // закрашивать с помощью stroke

export const CheckboxCheckedThird = `
<svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="13" height="13" rx="2.5"/>
<path d="M5 7H9" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
` // закрашивать с помощью stroke

export const CheckboxCheckedRow = `
<svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="13" height="13" rx="2.5"/>
<path d="M4 7L6 9L10 5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
` // закрашивать с помощью fill

export const HeadArrDown = `
<svg viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" d="M3.29289 4.29289C3.68342 4.68342 4.31658 4.68342 4.70711 4.29289L7.2929 1.70711C7.92286 1.07714 7.47669 0 6.58579 0H1.41421C0.523309 0 0.077142 1.07714 0.707107 1.70711L3.29289 4.29289Z" fill="white"/>
</svg>
` // закрашивать с помощью fill

export const HeadArrUp = `
<svg viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" d="M3.29289 0.707106C3.68342 0.316582 4.31658 0.316583 4.70711 0.707107L7.2929 3.29289C7.92286 3.92286 7.47669 5 6.58579 5H1.41421C0.523309 5 0.077142 3.92286 0.707107 3.29289L3.29289 0.707106Z" fill="white"/>
</svg>
` // закрашивать с помощью fill

export const CancelModal = `
<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.46967 5.46972C5.76256 5.17683 6.23744 5.17683 6.53033 5.46972L9.00001 7.9394L11.4697 5.46973C11.7626 5.17684 12.2374 5.17684 12.5303 5.46973C12.8232 5.76262 12.8232 6.2375 12.5303 6.53039L10.0607 9.00006L12.5303 11.4697C12.8232 11.7626 12.8232 12.2375 12.5303 12.5304C12.2374 12.8233 11.7626 12.8233 11.4697 12.5304L9.00001 10.0607L6.53034 12.5304C6.23744 12.8233 5.76257 12.8233 5.46968 12.5304C5.17678 12.2375 5.17678 11.7626 5.46968 11.4697L7.93935 9.00006L5.46967 6.53038C5.17678 6.23749 5.17678 5.76262 5.46967 5.46972Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.31673 0.768819C7.40429 0.42374 10.5957 0.42374 13.6832 0.768819C15.5096 0.972944 16.9845 2.41159 17.1994 4.24855C17.5686 7.40547 17.5686 10.5947 17.1994 13.7516C16.9845 15.5885 15.5096 17.0272 13.6832 17.2313C10.5957 17.5764 7.40429 17.5764 4.31673 17.2313C2.49035 17.0272 1.01545 15.5885 0.800605 13.7516C0.431374 10.5947 0.431374 7.40547 0.800605 4.24855C1.01545 2.41159 2.49035 0.972944 4.31673 0.768819ZM13.5166 2.25954C10.5398 1.92683 7.46017 1.92683 4.48334 2.25954C3.33891 2.38744 2.42286 3.29069 2.29045 4.4228C1.93476 7.46395 1.93476 10.5362 2.29045 13.5773C2.42286 14.7094 3.33891 15.6127 4.48334 15.7406C7.46017 16.0733 10.5398 16.0733 13.5166 15.7406C14.6611 15.6127 15.5771 14.7094 15.7095 13.5773C16.0652 10.5362 16.0652 7.46395 15.7095 4.4228C15.5771 3.29069 14.6611 2.38744 13.5166 2.25954Z"/>
</svg>
` // закрашивать с помощью fill

export const ApplyModal = `
<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path transform="translate(-3, -2.5)" fill-rule="evenodd" clip-rule="evenodd" d="M18 7.96967C18.3232 8.26256 18.3232 8.73744 18 9.03033L11 16.0303C10.7374 16.3232 10.2626 16.3232 9.96967 16.0303L5.96967 12.0303C5.67678 11.7374 5.67678 11.2626 5.96967 10.9697C6.26256 10.6768 6.73744 10.6768 7.03033 10.9697L10.5 14.4393L16.9697 7.96967C17.2626 7.67678 17.7374 7.67678 18.0303 7.96967Z" fill="#0B9F0B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.31673 0.768819C7.40429 0.42374 10.5957 0.42374 13.6832 0.768819C15.5096 0.972944 16.9845 2.41159 17.1994 4.24855C17.5686 7.40547 17.5686 10.5947 17.1994 13.7516C16.9845 15.5885 15.5096 17.0272 13.6832 17.2313C10.5957 17.5764 7.40429 17.5764 4.31673 17.2313C2.49035 17.0272 1.01545 15.5885 0.800605 13.7516C0.431374 10.5947 0.431374 7.40547 0.800605 4.24855C1.01545 2.41159 2.49035 0.972944 4.31673 0.768819ZM13.5166 2.25954C10.5398 1.92683 7.46017 1.92683 4.48334 2.25954C3.33891 2.38744 2.42286 3.29069 2.29045 4.4228C1.93476 7.46395 1.93476 10.5362 2.29045 13.5773C2.42286 14.7094 3.33891 15.6127 4.48334 15.7406C7.46017 16.0733 10.5398 16.0733 13.5166 15.7406C14.6611 15.6127 15.5771 14.7094 15.7095 13.5773C16.0652 10.5362 16.0652 7.46395 15.7095 4.4228C15.5771 3.29069 14.6611 2.38744 13.5166 2.25954Z"/>
</svg>
` // закрашивать с помощью fill

export const CalendarIcon = `
<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.83342 0.708008C4.1786 0.708008 4.45842 0.98783 4.45842 1.33301V2.7227C6.81487 2.51284 9.18531 2.51284 11.5418 2.7227V1.33301C11.5418 0.98783 11.8216 0.708008 12.1668 0.708008C12.5119 0.708008 12.7918 0.98783 12.7918 1.33301V2.8467C14.036 3.02263 15.0189 4.00673 15.1853 5.26204L15.2574 5.80616C15.5608 8.09477 15.5346 10.415 15.1796 12.6962C15.0038 13.8263 14.0857 14.6942 12.9476 14.8065L11.9534 14.9045C9.32423 15.1638 6.6759 15.1638 4.0467 14.9045L3.05253 14.8065C1.91441 14.6942 0.996359 13.8263 0.820518 12.6962C0.465553 10.415 0.439352 8.09477 0.742715 5.80617L0.814841 5.26204C0.981239 4.00672 1.9642 3.0226 3.20842 2.8467V1.33301C3.20842 0.98783 3.48825 0.708008 3.83342 0.708008ZM4.2044 4.00201C6.72872 3.75307 9.27141 3.75307 11.7957 4.00201L12.5503 4.07642C13.2726 4.14765 13.8508 4.70679 13.9461 5.42629L14.0183 5.97042C14.0435 6.16051 14.0663 6.35083 14.0867 6.54134H1.91348C1.93388 6.35083 1.95668 6.16051 1.98188 5.97042L2.054 5.42629C2.14937 4.70679 2.72756 4.14765 3.44985 4.07642L4.2044 4.00201ZM1.81412 7.79134C1.7323 9.36474 1.81291 10.944 2.05565 12.504C2.14385 13.0708 2.60434 13.5062 3.1752 13.5625L4.16937 13.6605C6.71699 13.9118 9.28314 13.9118 11.8308 13.6605L12.8249 13.5625C13.3958 13.5062 13.8563 13.0708 13.9445 12.504C14.1872 10.944 14.2678 9.36474 14.186 7.79134H1.81412Z"/>
</svg>
` // закрашивать с помощью fill

export const Link = `
<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H7.16667C7.625 2.16667 8 1.79167 8 1.33333C8 0.875 7.625 0.5 7.16667 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8.83333C15.5 8.375 15.125 8 14.6667 8C14.2083 8 13.8333 8.375 13.8333 8.83333V13C13.8333 13.4583 13.4583 13.8333 13 13.8333ZM9.66667 1.33333C9.66667 1.79167 10.0417 2.16667 10.5 2.16667H12.6583L5.05 9.775C4.725 10.1 4.725 10.625 5.05 10.95C5.375 11.275 5.9 11.275 6.225 10.95L13.8333 3.34167V5.5C13.8333 5.95833 14.2083 6.33333 14.6667 6.33333C15.125 6.33333 15.5 5.95833 15.5 5.5V1.33333C15.5 0.875 15.125 0.5 14.6667 0.5H10.5C10.0417 0.5 9.66667 0.875 9.66667 1.33333Z" />
</svg>
` // закрашивать с помощью fill

export const Edit = `
<svg viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.1369 0.46967C11.9963 0.329018 11.8055 0.25 11.6066 0.25C11.4077 0.25 11.2169 0.329018 11.0763 0.46967L1.88388 9.66206C1.78965 9.75628 1.72223 9.87391 1.68856 10.0028L0.688556 13.8313C0.621266 14.0889 0.695605 14.3629 0.88388 14.5511C1.07215 14.7394 1.34614 14.8138 1.60375 14.7465L5.43218 13.7465C5.56111 13.7128 5.67874 13.6454 5.77297 13.5511L14.9654 4.35876C15.2582 4.06586 15.2582 3.59099 14.9654 3.2981L12.1369 0.46967ZM3.08843 10.5788L11.6066 2.06066L13.3744 3.82843L4.8562 12.3466L2.46344 12.9716L3.08843 10.5788Z"/>
<path d="M1 16.25C0.585786 16.25 0.25 16.5858 0.25 17C0.25 17.4142 0.585786 17.75 1 17.75H16C16.4142 17.75 16.75 17.4142 16.75 17C16.75 16.5858 16.4142 16.25 16 16.25H1Z"/>
</svg>
` // закрашивать с помощью fill

export const ThreeTodsVertical = `
<svg viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.75 12C0.75 12.6904 1.30964 13.25 2 13.25C2.69036 13.25 3.25 12.6904 3.25 12C3.25 11.3096 2.69036 10.75 2 10.75C1.30964 10.75 0.75 11.3096 0.75 12Z"/>
<path d="M2 8.25C1.30964 8.25 0.75 7.69036 0.75 7C0.75 6.30964 1.30964 5.75 2 5.75C2.69036 5.75 3.25 6.30964 3.25 7C3.25 7.69036 2.69036 8.25 2 8.25Z"/>
<path d="M2 3.25C1.30964 3.25 0.75 2.69036 0.75 2C0.75 1.30964 1.30964 0.75 2 0.75C2.69036 0.75 3.25 1.30964 3.25 2C3.25 2.69036 2.69036 3.25 2 3.25Z"/>
</svg>
` // закрашивать с помощью fill

export const Download = `
<svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.25017 13.0507C5.2419 13.3948 5.46892 13.7004 5.80076 13.7918C6.1326 13.8832 6.48407 13.737 6.65319 13.4372L7.55549 11.8377C8.62558 9.94074 10.7558 8.90147 12.9095 9.22561L13.0588 9.24809C13.0856 9.92594 13.1237 10.6035 13.1731 11.2804L13.2409 12.211C13.2961 12.9678 14.14 13.3909 14.7794 12.9823C16.8771 11.6419 18.7007 9.91486 20.153 7.8931L20.6091 7.25816C20.7969 6.99669 20.7969 6.64451 20.6091 6.38303L20.153 5.7481C18.7007 3.72634 16.8771 1.99925 14.7794 0.658889C14.14 0.250306 13.2961 0.673376 13.2409 1.43019L13.1731 2.36083C13.1333 2.90656 13.1008 3.45269 13.0757 3.99907L12.0575 3.99907C8.39275 3.99907 5.39749 6.92317 5.30941 10.5868L5.25017 13.0507ZM13.1327 7.74232C10.7134 7.3782 8.31719 8.35219 6.83289 10.2306C7.09451 7.56748 9.33921 5.49907 12.0575 5.49907L13.7962 5.49907C14.2004 5.49907 14.5319 5.17871 14.5457 4.77471C14.572 4.00591 14.6132 3.23749 14.6691 2.46989L14.6745 2.39543C16.318 3.55709 17.7583 4.98548 18.9347 6.62323L19.0765 6.8206L18.9347 7.01797C17.7583 8.65572 16.318 10.0841 14.6745 11.2458L14.6691 11.1713C14.6061 10.3074 14.5619 9.44252 14.5365 8.5772C14.5259 8.21458 14.2572 7.91156 13.8985 7.85757L13.1327 7.74232Z"/>
<path d="M17.6413 15.4113C17.7592 14.403 17.8425 13.3916 17.891 12.379C17.8963 12.2694 17.946 12.1667 18.0283 12.0941C18.3916 11.7733 18.7437 11.44 19.0839 11.0948C19.2118 10.9649 19.4354 11.0557 19.4314 11.238C19.4002 12.6899 19.3001 14.1407 19.1311 15.5855C18.8946 17.6081 17.2703 19.1931 15.2582 19.418C11.7916 19.8054 8.20828 19.8054 4.74171 19.418C2.72965 19.1931 1.10532 17.6081 0.868765 15.5855C0.454227 12.0412 0.454227 8.4607 0.868765 4.9164C1.10532 2.89387 2.72965 1.30886 4.74171 1.08399C7.03268 0.827936 9.37465 0.741103 11.7002 0.823488C11.896 0.830424 12.0308 1.0221 12.0064 1.2165C12.0013 1.25698 11.9972 1.29793 11.9942 1.33932L11.9429 2.04314C11.9312 2.20473 11.7932 2.32767 11.6313 2.32199C9.38423 2.24315 7.12112 2.32739 4.90832 2.5747C3.57821 2.72336 2.51272 3.77298 2.35861 5.09065C1.95761 8.51918 1.95761 11.9828 2.35861 15.4113C2.51272 16.729 3.57821 17.7786 4.90832 17.9272C8.26417 18.3023 11.7357 18.3023 15.0916 17.9272C16.4217 17.7786 17.4872 16.729 17.6413 15.4113Z"/>
</svg>
` // закрашивать с помощью fill

export const Ok = `
<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.0303 0.96967C13.3232 1.26256 13.3232 1.73744 13.0303 2.03033L6.03033 9.03033C5.73744 9.32322 5.26256 9.32322 4.96967 9.03033L0.96967 5.03033C0.676777 4.73744 0.676777 4.26256 0.96967 3.96967C1.26256 3.67678 1.73744 3.67678 2.03033 3.96967L5.5 7.43934L11.9697 0.96967C12.2626 0.676777 12.7374 0.676777 13.0303 0.96967Z"/>
</svg>
` // закрашивать с помощью fill

export const Delete = `
<svg viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 0.25C5.58579 0.25 5.25 0.585786 5.25 1V1.75H1C0.585786 1.75 0.25 2.08579 0.25 2.5C0.25 2.91421 0.585786 3.25 1 3.25H15C15.4142 3.25 15.75 2.91421 15.75 2.5C15.75 2.08579 15.4142 1.75 15 1.75H10.75V1C10.75 0.585786 10.4142 0.25 10 0.25H6Z"/>
<path d="M9.06067 13L10.5303 14.4697C10.8232 14.7626 10.8232 15.2374 10.5303 15.5303C10.2374 15.8232 9.76256 15.8232 9.46967 15.5303L8.00001 14.0607L6.53034 15.5303C6.23744 15.8232 5.76257 15.8232 5.46968 15.5303C5.17678 15.2374 5.17678 14.7626 5.46968 14.4697L6.93935 13L5.46967 11.5303C5.17678 11.2374 5.17678 10.7626 5.46967 10.4697C5.76256 10.1768 6.23744 10.1768 6.53033 10.4697L8.00001 11.9393L9.46968 10.4697C9.76257 10.1768 10.2374 10.1768 10.5303 10.4697C10.8232 10.7626 10.8232 11.2374 10.5303 11.5303L9.06067 13Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.99142 5.91718C2.03363 5.53735 2.35468 5.25 2.73684 5.25H13.2632C13.6453 5.25 13.9664 5.53735 14.0086 5.91718L14.2087 7.71852C14.5715 10.9838 14.5715 14.2793 14.2087 17.5446L14.189 17.722C14.045 19.0181 13.0404 20.0517 11.7489 20.2325C9.26176 20.5807 6.73823 20.5807 4.25108 20.2325C2.95954 20.0517 1.955 19.0181 1.81098 17.722L1.79128 17.5446C1.42846 14.2793 1.42846 10.9838 1.79128 7.71852L1.99142 5.91718ZM3.40812 6.75L3.2821 7.88417C2.93152 11.0394 2.93152 14.2238 3.2821 17.379L3.3018 17.5563C3.37011 18.171 3.84652 18.6612 4.45905 18.747C6.80822 19.0758 9.19177 19.0758 11.5409 18.747C12.1535 18.6612 12.6299 18.171 12.6982 17.5563L12.7179 17.379C13.0685 14.2238 13.0685 11.0394 12.7179 7.88417L12.5919 6.75H3.40812Z"/>
</svg>
` // закрашивать с помощью fill

export const Columns = `
<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.8076 1.95723C14.4735 1.54563 13.0265 1.54563 11.6924 1.95723C11.2409 6.64155 11.2409 11.3584 11.6924 16.0428C13.0265 16.4544 14.4735 16.4544 15.8076 16.0428C16.259 11.3584 16.259 6.64155 15.8076 1.95723ZM17.2905 1.70874C17.7647 6.55801 17.7647 11.442 17.2905 16.2912C17.2382 16.8258 16.8719 17.2751 16.3662 17.4392C14.6731 17.9889 12.8269 17.9889 11.1338 17.4392C10.628 17.2751 10.2617 16.8258 10.2094 16.2912C9.7352 11.442 9.7352 6.55801 10.2094 1.70874C10.2617 1.17419 10.628 0.724924 11.1338 0.560739C12.8269 0.0111262 14.6731 0.0111263 16.3662 0.560739C16.8719 0.724924 17.2382 1.17419 17.2905 1.70874Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.30758 1.95723C4.97349 1.54563 3.52645 1.54563 2.19236 1.95723C1.74089 6.64155 1.74089 11.3584 2.19236 16.0428C3.52645 16.4544 4.97349 16.4544 6.30758 16.0428C6.75904 11.3584 6.75904 6.64155 6.30758 1.95723ZM7.79051 1.70874C8.26474 6.55801 8.26473 11.442 7.7905 16.2912C7.73823 16.8258 7.37194 17.2751 6.86616 17.4392C5.17307 17.9889 3.32686 17.9889 1.63377 17.4392C1.128 17.2751 0.761708 16.8258 0.709432 16.2912C0.235202 11.442 0.235202 6.55801 0.709432 1.70874C0.761709 1.17419 1.128 0.724923 1.63377 0.560739C3.32686 0.0111257 5.17308 0.0111259 6.86617 0.560739C7.37194 0.724924 7.73823 1.17419 7.79051 1.70874Z"/>
</svg>
` // закрашивать с помощью fill

export const PlusNormal = `
<svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.75 1C6.75 0.585786 6.41421 0.25 6 0.25C5.58579 0.25 5.25 0.585786 5.25 1V5.25H1C0.585787 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585787 6.75 1 6.75H5.25V11C5.25 11.4142 5.58579 11.75 6 11.75C6.41421 11.75 6.75 11.4142 6.75 11V6.75H11C11.4142 6.75 11.75 6.41421 11.75 6C11.75 5.58579 11.4142 5.25 11 5.25H6.75V1Z"/>
</svg>
` // закрашивать с помощью fill

export const Sites = `
<svg viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9241 2.41833C9.33097 1.9836 6.66886 1.9836 4.07573 2.41833C3.58773 2.50014 3.20014 2.87507 3.10287 3.35801C2.21779 7.75199 2.1695 12.2735 2.96052 16.6854L3.12407 17.5976L6.7946 14.1106C7.47004 13.4689 8.52978 13.4689 9.20522 14.1106L12.8758 17.5976L13.0393 16.6854C13.8303 12.2735 13.782 7.75199 12.897 3.35801C12.7997 2.87507 12.4121 2.50014 11.9241 2.41833ZM3.82772 0.938977C6.58504 0.476721 9.41478 0.476721 12.1721 0.938977C13.2713 1.12326 14.1469 1.96707 14.3674 3.06181C15.2898 7.64072 15.3401 12.3525 14.5158 16.9501L14.2943 18.1854C14.1084 19.2223 12.8443 19.6367 12.0806 18.9112L8.1721 15.1981C8.07561 15.1064 7.92422 15.1064 7.82772 15.1981L3.91919 18.9112C3.15549 19.6367 1.89145 19.2223 1.70555 18.1854L1.48407 16.9501C0.659748 12.3525 0.710073 7.64073 1.6324 3.06181C1.85291 1.96707 2.72851 1.12326 3.82772 0.938977Z"/>
</svg>
` // закрашивать с помощью fill

export const Eye = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.32343 10.6464C3.90431 11.2503 3.75 11.7227 3.75 12C3.75 12.2773 3.90431 12.7497 4.32343 13.3536C4.72857 13.9374 5.33078 14.5703 6.09267 15.155C7.61978 16.3271 9.71345 17.25 12 17.25C14.2865 17.25 16.3802 16.3271 17.9073 15.155C18.6692 14.5703 19.2714 13.9374 19.6766 13.3536C20.0957 12.7497 20.25 12.2773 20.25 12C20.25 11.7227 20.0957 11.2503 19.6766 10.6464C19.2714 10.0626 18.6692 9.42972 17.9073 8.84497C16.3802 7.67292 14.2865 6.75 12 6.75C9.71345 6.75 7.61978 7.67292 6.09267 8.84497C5.33078 9.42972 4.72857 10.0626 4.32343 10.6464ZM5.17941 7.65503C6.90965 6.32708 9.31598 5.25 12 5.25C14.684 5.25 17.0903 6.32708 18.8206 7.65503C19.6874 8.32028 20.4032 9.06244 20.9089 9.79115C21.4006 10.4997 21.75 11.2773 21.75 12C21.75 12.7227 21.4006 13.5003 20.9089 14.2089C20.4032 14.9376 19.6874 15.6797 18.8206 16.345C17.0903 17.6729 14.684 18.75 12 18.75C9.31598 18.75 6.90965 17.6729 5.17941 16.345C4.31262 15.6797 3.59681 14.9376 3.0911 14.2089C2.59937 13.5003 2.25 12.7227 2.25 12C2.25 11.2773 2.59937 10.4997 3.0911 9.79115C3.59681 9.06244 4.31262 8.32028 5.17941 7.65503Z"/>
</svg>
` // закрашивать с помощью fill

export const EyeCross = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.5303 4.53033C20.8232 4.23744 20.8232 3.76256 20.5303 3.46967C20.2374 3.17678 19.7626 3.17678 19.4697 3.46967L3.46967 19.4697C3.17678 19.7626 3.17678 20.2374 3.46967 20.5303C3.76256 20.8232 4.23744 20.8232 4.53033 20.5303L7.37723 17.6834C8.74353 18.3266 10.3172 18.75 12 18.75C14.684 18.75 17.0903 17.6729 18.8206 16.345C19.6874 15.6797 20.4032 14.9376 20.9089 14.2089C21.4006 13.5003 21.75 12.7227 21.75 12C21.75 11.2773 21.4006 10.4997 20.9089 9.79115C20.4032 9.06244 19.6874 8.32028 18.8206 7.65503C18.5585 7.45385 18.2808 7.25842 17.989 7.07163L20.5303 4.53033ZM16.8995 8.16113L15.1287 9.93196C15.5213 10.5248 15.75 11.2357 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C11.2357 15.75 10.5248 15.5213 9.93196 15.1287L8.51524 16.5454C9.58077 16.9795 10.7621 17.25 12 17.25C14.2865 17.25 16.3802 16.3271 17.9073 15.155C18.6692 14.5703 19.2714 13.9374 19.6766 13.3536C20.0957 12.7497 20.25 12.2773 20.25 12C20.25 11.7227 20.0957 11.2503 19.6766 10.6464C19.2714 10.0626 18.6692 9.42972 17.9073 8.84497C17.5941 8.60461 17.2571 8.37472 16.8995 8.16113ZM11.0299 14.0307C11.3237 14.1713 11.6526 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 11.6526 14.1713 11.3237 14.0307 11.0299L11.0299 14.0307Z"/>
<path d="M12 5.25C13.0323 5.25 14.0236 5.40934 14.9511 5.68101C15.1296 5.73328 15.1827 5.95662 15.0513 6.0881L14.2267 6.91265C14.1648 6.97451 14.0752 6.99928 13.99 6.97967C13.3506 6.83257 12.6839 6.75 12 6.75C9.71345 6.75 7.61978 7.67292 6.09267 8.84497C5.33078 9.42972 4.72857 10.0626 4.32343 10.6464C3.90431 11.2503 3.75 11.7227 3.75 12C3.75 12.2773 3.90431 12.7497 4.32343 13.3536C4.67725 13.8635 5.18138 14.4107 5.81091 14.9307C5.92677 15.0264 5.93781 15.2015 5.83156 15.3078L5.12265 16.0167C5.03234 16.107 4.88823 16.1149 4.79037 16.0329C4.09739 15.4517 3.51902 14.8255 3.0911 14.2089C2.59937 13.5003 2.25 12.7227 2.25 12C2.25 11.2773 2.59937 10.4997 3.0911 9.79115C3.59681 9.06244 4.31262 8.32028 5.17941 7.65503C6.90965 6.32708 9.31598 5.25 12 5.25Z"/>
<path d="M12 8.25C12.1185 8.25 12.2357 8.25549 12.3513 8.26624C12.5482 8.28453 12.6194 8.51991 12.4796 8.6597L11.2674 9.87196C10.6141 10.0968 10.0968 10.6141 9.87196 11.2674L8.6597 12.4796C8.51991 12.6194 8.28453 12.5482 8.26624 12.3513C8.25549 12.2357 8.25 12.1185 8.25 12C8.25 9.92893 9.92893 8.25 12 8.25Z"/>
</svg>
` // закрашивать с помощью fill

export const Import = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.7379 16.6273C9.96427 16.6273 9.31895 16.036 9.2514 15.2654C9.11015 13.6541 9.07441 12.0356 9.14427 10.4203C9.05994 10.4147 8.97563 10.4088 8.89133 10.4026L7.40178 10.2941C6.44973 10.2247 5.91752 9.16309 6.43151 8.35871C7.5277 6.6432 9.53693 4.72314 11.1904 3.53541C11.6742 3.18786 12.3258 3.18786 12.8097 3.53541C14.4631 4.72314 16.4723 6.64319 17.5685 8.35871C18.0825 9.16309 17.5503 10.2247 16.5983 10.2941L15.1087 10.4026C15.0244 10.4088 14.9401 10.4147 14.8558 10.4203C14.9256 12.0356 14.8899 13.6541 14.7486 15.2654C14.6811 16.036 14.0358 16.6273 13.2622 16.6273H10.7379ZM10.6815 9.76253C10.5678 11.5498 10.589 13.3431 10.745 15.1273H13.255C13.411 13.3431 13.4323 11.5498 13.3186 9.76253C13.3058 9.56216 13.3739 9.36505 13.5077 9.21531C13.6414 9.06556 13.8296 8.9757 14.0301 8.96582C14.3535 8.94989 14.6767 8.93015 14.9997 8.90661L16.0815 8.82775C15.1219 7.41445 13.9204 6.1802 12.5313 5.18235L12 4.80071L11.4687 5.18235C10.0796 6.1802 8.87813 7.41446 7.91858 8.82775L9.00038 8.90661C9.32337 8.93015 9.64656 8.94989 9.9699 8.96582C10.1704 8.9757 10.3586 9.06556 10.4924 9.21531C10.6261 9.36505 10.6942 9.56216 10.6815 9.76253Z"/>
<path d="M5.75 17C5.75 16.5858 5.41421 16.25 5 16.25C4.58579 16.25 4.25 16.5858 4.25 17V19C4.25 19.9665 5.0335 20.75 6 20.75H18C18.9665 20.75 19.75 19.9665 19.75 19V17C19.75 16.5858 19.4142 16.25 19 16.25C18.5858 16.25 18.25 16.5858 18.25 17V19C18.25 19.1381 18.1381 19.25 18 19.25H6C5.86193 19.25 5.75 19.1381 5.75 19V17Z"/>
</svg>
` // закрашивать с помощью fill

export const Olumpys = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 35" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.4438 35L17.9438 0L0.443848 35H1.44385L7.94385 26L10.9438 28.5L17.4438 23L19.4438 24.5L24.9438 19.5L35.4438 34.5L36.4438 35ZM20.1432 22.2242L24.6432 18.2242L24.2445 17.7758L19.956 21.5878L18.156 19.7879L17.9522 19.5841L17.7404 19.7796L11.4522 25.5841L9.65598 23.7879L9.23172 24.2121L11.2317 26.2121L11.4355 26.4159L11.6473 26.2204L17.9355 20.4159L19.7317 22.2121L19.9317 22.4122L20.1432 22.2242Z"/>
</svg>
` // закрашивать с помощью fill

export const Clients = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9.99994 9.00002C9.99994 7.89545 10.8954 7.00002 11.9999 7.00002C13.1045 7.00002 13.9999 7.89545 13.9999 9.00002C13.9999 10.1046 13.1045 11 11.9999 11C10.8954 11 9.99994 10.1046 9.99994 9.00002Z"/>
  <path d="M10.9999 12.5C9.34308 12.5 7.99994 13.8432 7.99994 15.5C7.99994 16.0523 8.44765 16.5 8.99994 16.5H14.9999C15.5522 16.5 15.9999 16.0523 15.9999 15.5C15.9999 13.8432 14.6568 12.5 12.9999 12.5H10.9999Z"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.54347 2.8827C10.4993 2.45796 13.5006 2.45796 16.4564 2.8827C17.9219 3.09329 19.051 4.28268 19.1851 5.75714L19.3121 7.15287C19.6055 10.3776 19.6055 13.6224 19.3121 16.8472L19.1851 18.2429C19.051 19.7174 17.9219 20.9068 16.4564 21.1173C13.5006 21.5421 10.4993 21.5421 7.54347 21.1173C6.07798 20.9068 4.94888 19.7174 4.81475 18.2429L4.68779 16.8472C4.39444 13.6224 4.39444 10.3776 4.68779 7.15287L4.81475 5.75714C4.94888 4.28268 6.07798 3.09329 7.54347 2.8827ZM16.2431 4.36745C13.4288 3.96305 10.5711 3.96305 7.75682 4.36745C6.97903 4.47922 6.37977 5.11048 6.30859 5.89303L6.18162 7.28876C5.89649 10.4231 5.89649 13.5769 6.18162 16.7113L6.30859 18.107C6.37977 18.8896 6.97903 19.5208 7.75682 19.6326C10.5711 20.037 13.4288 20.037 16.2431 19.6326C17.0209 19.5208 17.6201 18.8896 17.6913 18.107L17.8183 16.7113C18.1034 13.5769 18.1034 10.4231 17.8183 7.28876L17.6913 5.89303C17.6201 5.11048 17.0209 4.47922 16.2431 4.36745Z"/>
</svg>
` // закрашивать с помощью fill

export const Maximize = `
<svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6641 6.34311C13.6641 6.75732 13.9999 7.09311 14.4141 7.09311L16.9068 7.09311V9.58575C16.9068 9.99996 17.2426 10.3358 17.6568 10.3358C18.071 10.3357 18.4068 9.99996 18.4068 9.58575V6.34311C18.4068 5.9289 18.071 5.59311 17.6568 5.59311L14.4141 5.59311C13.9999 5.59311 13.6641 5.9289 13.6641 6.34311Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6567 13.6642C17.2425 13.6642 16.9067 14 16.9067 14.4142L16.9067 16.9068L14.4141 16.9068C13.9999 16.9068 13.6641 17.2426 13.6641 17.6568C13.6641 18.071 13.9999 18.4068 14.4141 18.4068L17.6567 18.4068C18.071 18.4068 18.4067 18.071 18.4067 17.6568L18.4067 14.4142C18.4067 14 18.071 13.6642 17.6567 13.6642Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.34304 13.6642C6.75726 13.6642 7.09304 14 7.09304 14.4142L7.09304 16.9068L9.58569 16.9068C9.9999 16.9068 10.3357 17.2426 10.3357 17.6568C10.3357 18.071 9.9999 18.4068 9.58569 18.4068L6.34304 18.4068C5.92883 18.4068 5.59304 18.071 5.59304 17.6568L5.59304 14.4142C5.59304 14 5.92883 13.6642 6.34304 13.6642Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3357 6.34311C10.3357 6.75732 9.99987 7.09311 9.58566 7.09311L7.09302 7.09311L7.09302 9.58575C7.09302 9.99996 6.75723 10.3358 6.34302 10.3358C5.9288 10.3358 5.59302 9.99996 5.59302 9.58575L5.59302 6.34311C5.59302 5.9289 5.92881 5.59311 6.34302 5.59311L9.58566 5.59311C9.99987 5.59311 10.3357 5.9289 10.3357 6.34311Z"
      />
    </svg>
` // закрашивать с помощью fill


/*export const ThreeTodsHirisontal = `
<svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5C2.82843 3.5 3.5 2.82843 3.5 2C3.5 1.17157 2.82843 0.5 2 0.5Z" fill="#444444"/>
<path d="M6.5 2C6.5 1.17157 7.17157 0.5 8 0.5C8.82843 0.5 9.5 1.17157 9.5 2C9.5 2.82843 8.82843 3.5 8 3.5C7.17157 3.5 6.5 2.82843 6.5 2Z" fill="#444444"/>
<path d="M12.5 2C12.5 1.17157 13.1716 0.5 14 0.5C14.8284 0.5 15.5 1.17157 15.5 2C15.5 2.82843 14.8284 3.5 14 3.5C13.1716 3.5 12.5 2.82843 12.5 2Z" fill="#444444"/>
</svg>
` */ // закрашивать с помощью fill

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/main.scss'
import './styles/main-menu.scss'
import './styles/login.scss'
import './styles/inputs.scss'
import './styles/buttons.scss'
import './styles/header-page.scss'
import './styles/modal.scss'
import './styles/grid.scss'
import './styles/profile.scss'
import 'v-calendar/dist/style.css'
import Notifications from '@kyvg/vue3-notification'

import {
  // ElButton,
  // ElButtonGroup,
  // ElInput,
  // ElCheckbox,
  // ElContainer,
  // ElAside,
  ElScrollbar,
  // ElHeader,
  // ElMain,
  // ElFooter,
  // ElMenu,
  // ElMenuItem,
  // ElAvatar,
  // ElDropdown,
  // ElDialog,
  // ElSelect,
  // ElSelectV2,
  // ElOption,
  // ElDrawer,
  // ElCollapse,
  // ElCollapseItem,
  // ElTabs,
  // ElTabPane,
  // ElTag,
  // ElCol,
  // ElRow,
  // ElSwitch,
  // ElDatePicker,
  // ElTimePicker,
  // ElRadioGroup,
  // ElRadio,
  // ElUpload,
  ElTable,
  ElTableColumn,
  ElPopover,
  ElSegmented,
  ElLoading,
  ElDialog,
  ElSwitch,
  ElDatePicker,
  ElSelectV2
} from 'element-plus'

import 'element-plus/dist/index.css'

createApp(App)
  .use(store)
  .use(router)
  .use(Notifications)
  //.use(ElCollapse)
  //.use(ElCollapseItem)
  .use(ElScrollbar)
  //.use(ElTabs)
  //.use(ElTabPane)
  .use(ElTable)
  .use(ElTableColumn)
  .use(ElPopover)
  .use(ElSegmented)
  .use(ElLoading)
  .use(ElDialog)
  .use(ElSwitch)
  .use(ElDatePicker)
  .use(ElSelectV2)
  .mount('#app')

import store from '../store'

class Permission {
  name = null
  key = null

  constructor(name, key) {
    /*for (const key in params) {
      if(key in this) 
        this[key] = params[key]
    }*/

    this.name = name
    this.key = key
  }
}

export const permissions = {
  create_user: new Permission('Добавлять пользователя', 'create_user'),
  get_users: new Permission('Просматривать пользователей', 'get_users'),
  get_user: new Permission('Просматривать пользователя', 'get_user'),
  update_user: new Permission('Изменять пользователя', 'update_user'),
  delete_user: new Permission('Удалять пользователя', 'delete_user'),
  create_role: new Permission('Добавлять роль', 'create_role'),
  get_roles: new Permission('Просматривать роли', 'get_roles'),
  update_role: new Permission('Изменять роль', 'update_role'),
  delete_role: new Permission('Удалять роль', 'delete_role'),
  get_links: new Permission('Просмотр links', 'get_links'),
  delete_link: new Permission('Удаление link', 'delete_link'),
  delete_links: new Permission('Удаление links', 'delete_links'),
  export_links_to_csv: new Permission('Выгрузка CSV', 'export_links_to_csv'),
  create_site: new Permission('Добавлять сайт', 'create_site'),
  get_sites: new Permission('Просматривать сайт', 'get_sites'),
  update_site: new Permission('Изменять сайт', 'update_site'),
  delete_site: new Permission('Удалять сайт', 'delete_site'),
  create_target_page: new Permission(
    'Добавлять таргет пэйдж',
    'create_target_page'
  ),
  get_target_pages: new Permission(
    'Просматривать таргет пэйдж',
    'get_target_pages'
  ),
  update_target_page: new Permission(
    'Изменять таргет пэйдж',
    'update_target_page'
  ),
  delete_target_page: new Permission(
    'Удалять таргет пэйдж',
    'delete_target_page'
  ),
  delete_target_pages: new Permission(
    'Удалять таргет пэйджи',
    'delete_target_pages'
  ),
  export_target_pages_to_csv: new Permission(
    'Выгрузка таргет пэйдж в CSV',
    'export_target_pages_to_csv'
  ),
  create_link_from_do: new Permission(
    'Добавлять link from do',
    'create_link_from_do'
  ),
  get_partner_links_from_do: new Permission(
    'Просматривать links from do',
    'get_partner_links_from_do'
  ),
  update_link_from_do: new Permission(
    'Изменять link from do',
    'update_link_from_do'
  ),
  create_link_from_partner: new Permission(
    'Добавлять link from partner',
    'create_link_from_partner'
  ),
  get_partner_links_from_partner: new Permission(
    'Просматривать links from partner',
    'get_partner_links_from_partner'
  ),
  update_link_from_partner: new Permission(
    'Изменять link from partner',
    'update_link_from_partner'
  ),
  delete_partner_link_from_do_partner: new Permission(
    'Удалять link from do and partners',
    'delete_partner_link_from_do_partner'
  ),
  delete_partner_links_from_do_partner: new Permission(
    'Удалять  links from do and partners',
    'delete_partner_links_from_do_partner'
  ),
  export_links_from_do_to_csv: new Permission(
    'Выгрузка link from do в CSV',
    'export_links_from_do_to_csv'
  ),
  export_links_from_partner_to_csv: new Permission(
    'Выгрузка link from partner в CSV',
    'export_links_from_partner_to_csv'
  ),
  create_partner: new Permission('Создавать партнера', 'create_partner'),
  get_partners: new Permission('Просматривать партнеров', 'get_partners'),
  update_partner: new Permission('Изменять партнера', 'update_partner'),
  delete_partner: new Permission('Удалять партнера', 'delete_partner'),
  delete_partners: new Permission('Удалять партнеров', 'delete_partners'),
  export_partners_to_csv: new Permission(
    'Выгрузка CSV',
    'export_partners_to_csv'
  ),
  create_link_status: new Permission(
    'Создавать link status',
    'create_link_status'
  ),
  get_link_statuses: new Permission(
    'Просматривать link statuses',
    'get_link_statuses'
  ),
  get_link_status: new Permission(
    'Просматривать link status',
    'get_link_status'
  ),
  update_link_status: new Permission(
    'Изменять link status',
    'update_link_status'
  ),
  delete_link_status: new Permission(
    'Удалять link status',
    'delete_link_status'
  ),
  create_niche: new Permission('Создавать niches', 'create_niche'),
  get_niches: new Permission('Просматривать niches', 'get_niches'),
  update_niche: new Permission('Изменять niches', 'update_niche'),
  delete_niche: new Permission('Удалять niches', 'delete_niche'),
  create_partner_status: new Permission(
    'Создавать Partner status',
    'create_partner_status'
  ),
  get_partner_status: new Permission(
    'Просматривать Partner status',
    'get_partner_status'
  ),
  get_partner_statuses: new Permission(
    'Просматривать Partner statuses',
    'get_partner_statuses'
  ),
  update_partner_status: new Permission(
    'Изменять Partner status',
    'update_partner_status'
  ),
  delete_partner_status: new Permission(
    'Удалять Partner status',
    'delete_partner_status'
  ),
  get_permissions: new Permission(
    'Просматривать permissions',
    'get_permissions'
  ),
  update_permissions: new Permission(
    'Изменять permissions',
    'update_permissions'
  ),
  create_partner_site: new Permission(
    'Создавать partner site',
    'create_partner_site'
  ),
  get_partner_sites: new Permission(
    'Просматривать partner sites',
    'get_partner_sites'
  ),
  update_partner_site: new Permission(
    'Изменять partner site',
    'update_partner_site'
  ),
  delete_partner_site: new Permission(
    'Удалять partner site',
    'delete_partner_site'
  ),
  export_sites: new Permission('Выгрузка CSV partner sites', 'export_sites'),
  update_link: new Permission('Изменять link', 'update_link'),
  create_site_status: new Permission(
    'Создавать site status',
    'create_site_status'
  ),
  get_site_statuses: new Permission(
    'Просматривать site statuses',
    'get_site_statuses'
  ),
  get_site_status: new Permission(
    'Просматривать site status',
    'get_site_status'
  ),
  update_site_status: new Permission(
    'Изменять site status',
    'update_site_status'
  ),
  delete_site_status: new Permission(
    'Удалять site status',
    'delete_site_status'
  ),
  show_settings: new Permission(
    'Показывать пункты меню Settings',
    'show_settings'
  ),

  get_history: new Permission('Показывать историю Изменений', 'get_history'),
  get_partner_history: new Permission(
    'Показывать историю Изменений партнера',
    'get_partner_history'
  ),

  get_client_history_total: new Permission(
    'Показывать историю Изменений',
    'get_client_history_total'
  ),
  get_client_history: new Permission(
    'Показывать историю Изменений клиєнта',
    'get_client_history'
  ),

  import_links_from_csv: new Permission(
    'Импорт линков',
    'import_links_from_csv'
  ),
  create_link_reasons: new Permission('Create reason', 'create_link_reasons'),
  get_link_reasons: new Permission('View reason', 'get_link_reasons'),
  update_link_reasons: new Permission('Edit reason', 'update_link_reasons'),
  delete_link_reasons: new Permission('Delete reason', 'delete_link_reasons'),

  ahrefs_upload_links: new Permission(
    'ahrefs upload links',
    'ahrefs_upload_links'
  ),

  create_client_status: new Permission(
    'Создавать client status',
    'create_client_status'
  ),
  get_client_statuses: new Permission(
    'Просматривать client statuses',
    'get_client_statuses'
  ),
  get_client_status: new Permission(
    'Просматривать client status',
    'get_client_status'
  ),
  update_client_status: new Permission(
    'Изменять client status',
    'update_client_status'
  ),
  delete_client_status: new Permission(
    'Удалять client status',
    'delete_client_status'
  ),

  get_client_sites: new Permission('View client sites', 'get_client_sites'),
  update_client_site: new Permission(
    'Update client site',
    'update_client_site'
  ),
  create_client_site: new Permission(
    'Create client site',
    'create_client_site'
  ),
  delete_client_site: new Permission(
    'Deletes client site',
    'delete_client_site'
  ),
  delete_client_sites: new Permission(
    'Deletes client sites',
    'delete_client_sites'
  ),
  export_client_sites_to_csv: new Permission(
    'Export client cites to csv',
    'export_client_sites_to_csv'
  ),

  export_client_target_pages_to_csv: new Permission(
    'export client target pages to csv',
    'export_client_target_pages_to_csv'
  ),

  delete_client_target_pages: new Permission(
    'delete client target pages',
    'delete_client_target_pages'
  ),

  create_client_target_page: new Permission(
    'create client target page',
    'create_client_target_page'
  ),

  get_client_target_pages: new Permission(
    'get client target pages',
    'get_client_target_pages'
  ),

  update_client: new Permission('Изменять client', 'update_client'),
  get_clients: new Permission('View clients', 'get_clients'),
  create_client: new Permission('Create client', 'create_client'),
  delete_client: new Permission('Deletes client', 'delete_client'),
  delete_clients: new Permission('Deletes clients', 'delete_clients'),
  create_client_contact: new Permission(
    'Create client contact',
    'create_client_contact'
  ),
  delete_client_contact: new Permission(
    'Delete client contact',
    'delete_client_contact'
  ),
  update_client_contact: new Permission(
    'Edit client contact',
    'update_client_contact'
  ),
  export_clients_to_csv: new Permission(
    'Выгрузка CSV',
    'export_clients_to_csv'
  ),

  get_client_links: new Permission('get client links', 'get_client_links'),

  export_client_links_to_csv: new Permission(
    'Выгрузка client links CSV',
    'export_client_links_to_csv'
  ),

  create_client_link: new Permission(
    'create client link',
    'create_client_link'
  ),

  update_client_link: new Permission(
    'update client link',
    'update_client_link'
  ),

  delete_client_link: new Permission(
    'delete client link',
    'delete_client_link'
  ),

  delete_client_links: new Permission(
    'delete client links',
    'delete_client_links'
  ),

  create_client_invoice: new Permission(
    'Создавать Invoice',
    'create_client_invoice'
  ),
  get_client_invoices: new Permission(
    'Просматривать Invoices',
    'get_client_invoices'
  ),
  update_client_invoice: new Permission(
    'Изменять Invoice',
    'update_client_invoice'
  ),
  delete_client_invoice: new Permission(
    'Удалять Invoice',
    'delete_client_invoice'
  ),
  delete_client_invoices: new Permission(
    'Удалять Invoices',
    'delete_client_invoices'
  ),
  export_client_invoices_to_csv: new Permission(
    'Выгрузка Invoice CSV',
    'export_client_invoices_to_csv'
  ),

  create_requirement: new Permission(
    'Create requirement',
    'create_requirement'
  ),
  get_requirements: new Permission('Get requirements', 'get_requirements'),
  update_requirement: new Permission(
    'Update requirement',
    'update_requirement'
  ),
  delete_requirement: new Permission(
    'Delete requirement',
    'delete_requirement'
  ),

  import_sites_from_csv: new Permission(
    'Импорт sites',
    'import_sites_from_csv'
  ),

  create_slack_community_status: new Permission(
    'create_slack_community_status',
    'create_slack_community_status'
  ),
  get_slack_community_statuses: new Permission(
    'get_slack_community_statuses',
    'get_slack_community_statuses'
  ),
  update_slack_community_status: new Permission(
    'update_slack_community_status',
    'update_slack_community_status'
  ),
  delete_slack_community_status: new Permission(
    'delete_slack_community_status',
    'delete_slack_community_status'
  ),

  get_ahrefs_sites: new Permission('get_ahrefs_sites', 'get_ahrefs_sites'),

  get_contacts: new Permission('get_contacts', 'get_contacts'),

  get_link_statuses_groups: new Permission(
    'get_link_statuses_groups',
    'get_link_statuses_groups'
  ),
  create_link_statuses_group: new Permission(
    'create_link_statuses_group',
    'create_link_statuses_group'
  ),
  update_link_statuses_group: new Permission(
    'update_link_statuses_group',
    'update_link_statuses_group'
  ),
  delete_link_statuses_group: new Permission(
    'delete_link_statuses_group',
    'delete_link_statuses_group'
  ),
}

export const hasAccess = ({ /*id, name, */ key } = { key: '' }) => {
  if (!key) {
    console.warn(`permission not found`)
    return false
  }

  const currentUser = store.state.currentUser

  if (currentUser && 'permissions' in currentUser) {
    //permissions = [{permission: "create_user"}]
    for (const permissionItem of currentUser.permissions) {
      if (permissionItem.permission === key) {
        return true
      }
    }
  }
  return false
}

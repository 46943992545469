<template>
    <teleport to='body' v-if="isLoading">
        <div class="loading">
            <div class="loading-block">
                Loading...
            </div>
        </div>
    </teleport>
</template>

<script>
export default {
    name: 'Loading',
    computed: {
        isLoading() {
            return this.$store.state.isLoading
        }
    }   
}
</script>

<style lang="scss" scoped>
    .loading {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
        .loading-block {
            background: rgb(69, 255, 153);
            color: rgb(32, 31, 43);
            padding: 10px;
            width: max-content;
            position: fixed;
            right: 0;
            top: 0;
        }
    }
</style>
import { createStore } from 'vuex'

export default createStore({
  state: {
    token: null,
    currentUser: null,
    isLoading: false,
    metaData: null,
    mainContentParams: null,
  },
  mutations: {
    setUser(state, data) {
      state.currentUser = data
    },
    setToken(state, data) {
      state.token = data
    },
    setIsLoading(state, data) {
      state.isLoading = data
    },
    setMetaData(state, data) {
      state.metaData = data
    },
    setMainContentParams(state, data) {
      state.mainContentParams = data
    },
  },
  actions: {
  },
  modules: {
  }
})

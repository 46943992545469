const svr = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/`
import { notify } from '@kyvg/vue3-notification' //https://github.com/kyvg/vue3-notification
import store from '../store'

export const urls = {
  authentication: `${svr}login`,
  me: `${svr}me`,
  updateMe: `${svr}update_me`,
  users: `${svr}users`,
  userCreate: `${svr}users`,
  linkStatuses: `${svr}links-statuses`,
  linkStatusesCreate: `${svr}links-statuses`,
  partnerStatuses: `${svr}partner-statuses`,
  partnerStatusesCreate: `${svr}partner-statuses`,
  niches: `${svr}niches`,
  nichesCreate: `${svr}niches`,
  roles: `${svr}roles`,
  subNiches: `${svr}sub_niches`,
  permissions: `${svr}permissions`,
  links: `${svr}links`,
  linksExportToCsv: `${svr}links/export_to_csv`,
  linksExportToCsvCopyLink: `${svr}links/get_csv_link`,
  partners: `${svr}partners`,
  partner: `${svr}partner-lbp-profile`,
  partnersExportToCsv: `${svr}partners/export_to_csv`,
  metaData: `${svr}metadata`,
  setColumns: `${svr}set_columns`,
  partnerContact: `${svr}partner-contact`,
  partnerTargetPages: `${svr}partner-target-pages`,
  partnerTargetPagesToCsv: `${svr}partner-target-pages/export_to_csv`,
  partnerLinksFromDo: `${svr}partner-links-from-do`,
  partnerLinksFromDoCreate: `${svr}partner-links-from-do`,
  partnerLinksFromDoPartnerDelete: `${svr}partner-links-from-do-partner`,
  partnerLinksFromDoToCsv: `${svr}partner-links-from-do/export_to_csv`,
  partnerLinksFromPartner: `${svr}partner-links-from-partner`,
  partnerLinksFromPartnerCreate: `${svr}partner-links-from-partner`,
  partnerLinksFromPartnerToCsv: `${svr}partner-links-from-partner/export_to_csv`,
  partnerSites: `${svr}partner-sites`,
  partnerSitesToCsv: `${svr}partner-sites/export_to_csv`,
  usersResetPassword: `${svr}users-reset-password`,
  usersSetPassword: `${svr}set-password`,
  usersSendInvite: `${svr}users-send-email`,
  contactsResetPassword: `${svr}contact-reset-password`,
  contactsSetPassword: `${svr}contact-set-password`,
  contactsSendInvite: `${svr}contact-send-email`,
  sites: `${svr}sites`,
  sitesToCsv: `${svr}sites/export_to_csv`,
  getSubNiches: `${svr}sub_niches`,
  changePassword: `${svr}change-my-password`,
  inviteStatuses: `${svr}invite_statuses`,
  siteStatuses: `${svr}site_statuses`,
  siteStatusesCreate: `${svr}site_statuses`,
  deletePartnerInfo: `${svr}delete_partner_info`,
  history: `${svr}history`,
  historyToCsv: `${svr}history_to_csv`,
  importLinks: `${svr}links-upload_csv`,

  reasons: `${svr}links-reasons`,
  reasonsCreate: `${svr}links-reasons`,
  ahrefsLinksUpload: `${svr}ahrefs_links-upload_csv`,

  client: `${svr}client-lbp-profile`,
  clientStatuses: `${svr}client_statuses`,
  clientStatusesCreate: `${svr}client_statuses`,

  clients: `${svr}clients`,
  clientContact: `${svr}client-contact`,
  clientContactsSendInvite: `${svr}client-contact-send-email`,
  clientContactsSetPassword: `${svr}client-contact-set-password`,
  deleteClientInfo: `${svr}delete_client_info`,
  clientsExportToCsv: `${svr}clients/export_to_csv`,

  clientSites: `${svr}client-sites`,
  clientSitesToCsv: `${svr}client-sites/export_to_csv`,

  clientTargetPages: `${svr}client-target-pages`,
  clientTargetPagesToCsv: `${svr}client-target-pages/export_to_csv`,

  acquiredLinks: `${svr}client-links`,
  acquiredLinksCreate: `${svr}client-links`,
  acquiredLinksPartnerDelete: `${svr}client-links`,
  acquiredLinksToCsv: `${svr}client-links/export_to_csv`,

  clientsHistory: `${svr}client_history`,
  clientsHistoryToCsv: `${svr}client_history_to_csv`,

  clientList: `${svr}clients-list`,

  clientInvoiceStatuses: `${svr}client-invoice-statuses`,
  clientInvoices: `${svr}client-invoices`,
  clientInvoiceUpdate: `${svr}client-invoice`,
  clientInvoiceDelete: `${svr}client-invoice`,
  clientInvoicesDelete: `${svr}client-invoice`,
  clientInvoiceCreate: `${svr}client-invoice`,
  clientInvoiceToCsv: `${svr}client-invoice/export_to_csv`,

  requirements: `${svr}requirements`,
  requirementsCreate: `${svr}requirements`,
  requirementsDelete: `${svr}requirements`,
  requirementsUpdate: `${svr}requirements`,

  parserStatuser: `${svr}parser-statuses`,

  importSites: `${svr}sites_upload_csv`,

  slackCommunityStatus: `${svr}slack-community-status`,
  slackCommunityStatusCreate: `${svr}slack-community-status`,
  slackCommunityStatusDelete: `${svr}slack-community-status`,
  slackCommunityStatusUpdate: `${svr}slack-community-status`,

  analyticsStatusesLinksByLinkbuilder: `${svr}statuses_links_by_linkbuilder`,
  analyticsCountClientAcquiredLinks: `${svr}count_client_acquired_links`,
  analyticsSumPublishedLinks: `${svr}sum_published_links`,
  analyticsSumClientsLinks: `${svr}sum_clients_links`,
  analyticsAllLinks: `${svr}all_links`,
  analyticsClientBudgetProgress: `${svr}client_budget_progress`,
  analyticsFailedLinksLinkBuilder: `${svr}failed_links_by_linkbuilder`,
  analyticsStuckLinks: `${svr}stuck_links_by_linkbuilder`,
  analyticsCountOfClientLinkswithSlackCommunity: `${svr}slack_community_links`,
  

  partnersCompanyNames: `${svr}partners-company-names`,
  importAhrefs: `${svr}ahrefs-sites`,

  contacts: `${svr}contacts`,
  
  linkStatusesGroups: `${svr}groups`,
  linkStatusesGroupsCreate: `${svr}groups`,
  linkStatusesGroupsUpdate: `${svr}groups`,
  linkStatusesGroupsDelete: `${svr}groups`,
}

export async function fetchData(url, type, userData, query = '') {
  if (query) query = '?' + query

  const token = localStorage.getItem('userAuth')
  let requestData = {
    method: type,
    body: JSON.stringify(userData),
    headers: {
      Authorization: `Bearer ${JSON.parse(token).access_token}`,
      'Content-Type': 'application/json',
    },
  }

  if (!userData) delete requestData.body

  try {
    const response = await fetch(url + query, requestData)

    if (response.status === 401) {
      localStorage.removeItem('userAuth')
      window.location.href = '/login'
    }

    let result = await response.json()

    if (response.status > 399 && response.status !== 422) {
      let errorText = ''

      if (typeof result?.detail === 'object' && result?.detail?.length) {
        result.detail.forEach((element) => {
          errorText += element.msg + '. '
        })
      } else if (
        typeof result?.detail === 'object' &&
        !result?.detail?.length
      ) {
        errorText += result.detail.msg + '. '
      } else if (typeof result?.detail === 'string') {
        errorText = result.detail
      } else if (typeof result === 'string') {
        errorText = result
      } else {
        errorText = result.detail?.msg || 'Error response ' + response.status
      }

      notify({
        text: errorText,
        type: 'error',
        duration: 2000,
      })
    }

    return result
  } catch (error) {
    store.commit('setIsLoading', false)
    notify({
      text: error,
      type: 'error',
      duration: 2000,
    })
    // eslint-disable-next-line
    console.log(error)
  }
}

function download(filename, text) {
  var pom = document.createElement('a')
  pom.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
  )
  pom.setAttribute('download', filename)
  pom.click()
}

export async function fetchCSV(url, type, fileName, userData, query = '') {
  if (query) query = '?' + query

  const token = localStorage.getItem('userAuth')
  let requestData = {
    method: type,
    body: JSON.stringify(userData),
    headers: {
      Authorization: `Bearer ${JSON.parse(token).access_token}`,
      'Content-Type': 'text/csv;charset=UTF-8',
    },
  }

  if (!userData) delete requestData.body

  try {
    const response = await fetch(url + query, requestData)

    if (response.status === 401) {
      localStorage.removeItem('userAuth')
      window.location.href = '/login'
    }

    if (response.status === 200) {
      download(fileName, await response.text())
      return
    }

    let result = response.json()
    if (response.status > 399) {
      let errorText = ''

      if (typeof result?.detail === 'object' && result?.detail?.length) {
        result.detail.forEach((element) => {
          errorText += element.msg + '. '
        })
      } else if (
        typeof result?.detail === 'object' &&
        !result?.detail?.length
      ) {
        errorText += result.detail.msg + '. '
      } else if (typeof result?.detail === 'string') {
        errorText = result.detail
      } else if (typeof result === 'string') {
        errorText = result
      } else {
        errorText = result.detail?.msg || 'Error response ' + response.status
      }

      notify({
        text: errorText,
        type: 'error',
        duration: 2000,
      })
    }
  } catch (error) {
    notify({
      text: error,
      type: 'error',
      duration: 2000,
    })
    // eslint-disable-next-line
    console.log(error)
  }
}

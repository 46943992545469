<template>
  <div class="colapse-button" :class="{[classStr]: classStr}" v-html="isColapsedBlock ? CaretDown : CaretUp"></div>
</template>

<script>
import { CaretDown, CaretUp} from '@/utils/icons'
export default {
  name: 'ColapseButton',
  props: {
    isColapsedBlock: {default: false},
    classStr: {default: null}
  },

  computed: {

  },

  data() {
    return {
      CaretDown, CaretUp
    }
  }
}
</script>

<style lang="scss">
.colapse-button {
  position: absolute;
  top: -1px;
  height: 25px;
  width: 30px;
  background: white;
  z-index: 1;
  border: 1px solid #858688;
  padding: 4px 6px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  cursor: pointer;
  left: 0px; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 

  svg {
    fill: #858688;
    height: auto;
    width: 16.5px;
  }

  &.left-menu {
    transform: rotate(270deg);
    left: -3px;
    right: auto;
    top: 10px;
  }
}
</style>